import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import faq from '../config/contents/faq';
import { KeyValueObject } from "../types/shared";

import '../assets/css/faq.css';

const FaqPage: React.FC<PageProps<any>> = ({ data }) => {
  const { pageImage, site } = data;
  const { title, path } = site?.siteMetadata?.pages?.faq;
  const { src: imageUrl } = pageImage?.childImageSharp.fluid;
  const heroBgStyle = {
    backgroundImage: `url(${imageUrl})`,
  };

  const onClick = (evnt: React.MouseEvent<HTMLButtonElement>) => {
    const { currentTarget } = evnt;
    currentTarget?.classList?.toggle('collapsed');
    if (typeof window !== 'undefined') {
      const $responseBlock = document?.querySelector(currentTarget?.dataset?.target as string);
      $responseBlock?.classList.toggle('show');
    }
  }

  return (
    <Layout>
      <SEO title={title} path={path} image={imageUrl} />

      <div className="d-block bg-white">

        <section className='position-relative hero__image-bg bg-darkengrey bg_size_cover' style={heroBgStyle}>

          <div className='container'>
            <div className="container__row row justify-content-center align-items-center">
              <div className='my-5 py-5'>
                <h1 className="title mt-3 mt-md-5 pt-3 pt-md-5 mb-4">
                  <span>Comment pouvons-nous</span>{` `}
                  <strong className="d-bold">vous aider ?</strong>
                </h1>
              </div>
            </div>
          </div>

        </section>

        <section className='bg-sliver py-3 py-md-5'>
          <div className="container">
            <div className="container__row row py-0 py-md-5">

              <div className="accordion w-100 py-0 pt-md-4 pb-md-5" id="accordionMenu">

                {
                  faq.map((item: KeyValueObject<string>) => (
                    <div className="card bg-sliver border-0 rounded-0" key={item.id}>

                      <div className="card-header p-0 bg-transparent border-0" id="headingOne">

                        <h2 className="mb-0 fsz-20 text-red">
                          <button
                            onClick={onClick}
                            className="btn btn-block text-left collapsed accordion__toggler position-relative py-3 rounded-0 d-bold"
                            type="button"
                            data-target={`#collapse-${item.id}`}
                            aria-expanded="true" aria-controls={`#collapse-${item.id}`}>
                            {item.question}
                          </button>
                        </h2>
                      </div>

                      <div id={`collapse-${item.id}`} className="collapse" aria-labelledby="headingOne" data-parent="#accordionMenu">
                        <div
                          className="card-body fsz-18 font-weight-normal"
                          dangerouslySetInnerHTML={{ __html: item.response }} />
                      </div>

                    </div>
                  ))
                }

              </div>

            </div>
          </div>
        </section>

      </div>

    </Layout>
  );
}

export const query = graphql`
  query faqQuery {
    site {
      siteMetadata {
        lang
        pages {
          faq {
            title
            path
          }
        }
      }
    }
    pageImage: file(relativePath: { eq: "faq/hero-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default FaqPage;
