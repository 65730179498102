import { signup, signin } from '../../assets/data/links.json';

const faq = [
  {
    id: `1`,
    question: `Comment ça marche ?`,
    response: `<ul className='p-0 m-0'>
    <li><a href="${signup}" target="_blank" rel="noopener noreferrer">Créez votre compte</a> gratuitement chez Dropzik pour accéder à tous nos services;</li>
    <li>Cliquez sur le lien de confirmation que vous recevrez par email;</li>
    <li><a href="${signin}" target="_blank" rel="noopener noreferrer">Connectez-vous</a> à votre tableau de bord personnel pour publier et gérer votre musique.</li>
    </ul>`,
  },
  {
    id: `2`,
    question: `Mon accord avec Dropzik est-il exclusif ?`,
    response: `Non, le contrat de distribution ne s'applique que pour une sortie et non pas pour l'ensemble de votre catalogue.
    Cependant, il n'est pas autorisé de distribuer votre sortie avec un autre distributeur digital en même temps.`,
  },
  {
    id: `3`,
    question: `Puis-je utiliser plus d'un distributeur digital pour une sortie ?`,
    response: `Non, vous ne pouvez pas distribuer votre sortie avec un autre distributeur digital en même temps, mais vous pouvez utiliser un distributeur digital différent pour vos autres sorties.`,
  },
  {
    id: `4`,
    question: `Comment passer d'un autre distributeur digital à Dropzik ?`,
    response: `Nous sommes heureux que vous envisagiez de redistribuer votre musique avec nous. 
    Avant cela, veuillez contacter votre distributeur actuel afin de demander le retrait de votre contenu de toutes les plateformes. Il n'est malheureusement pas possible de récupérer votre contenu directement. 
    Vous pourrez en parallèle reprogrammer la livraison de vos sorties avec notre plateforme. 
    N'oubliez pas de conserver vos codes UPC / ISRC déjà existant lorsque vous créez votre sortie sur Dropzik, afin de conserver toutes vos statistiques, classement et placement en playlist.
    Pour un changement de label sur Beatport, veuillez consulter la catégorie "Plateformes".
    `,
  },
  {
    id: `5`,
    question: `Quels genres de musique distribuez-vous ?`,
    response: `Bonne nouvelle: Nous distribuons tous les genres, y compris classique !
    Si vous ne trouvez pas le genre qui convient le mieux à vos besoins, veuillez nous envoyer un e-mail.`,
  },
  {
    id: `6`,
    question: `Combien de temps faut-il pour voir ma musique en ligne ?`,
    response: `Nous proposons 3 options de livraison pour votre sortie :
    <ul className='p-0 m-0'>
      <li>Livraison standard (2 semaines) : Gratuit pour tous les forfaits;</li>
      <li>Livraison express (1 semaine);</li>
      <li>Livraison prioritaire (2 - 4 jours ouvrables).</li>
    </ul>`,
  },
  {
    id: `12`,
    question: `Comment puis-je retirer une sortie ?`,
    response: `Les demandes de retrait de sortie sont gratuites.
    Il vous suffit de nous contacter par e-mail et votre sortie sera retirée sous un délai de 2 semaines.<br />
    <i>NB</i>: Les pistes qui ont été retirées seront toujours "shazamables". Merci de contacter le service client de Shazam si vous souhaitez que votre piste soit supprimée de leur base de données.`,
  },
  {
    id: `7`,
    question: `Quand est-ce que je vois mes ventes ?`,
    response: `Le revenu des ventes d'une sortie n'est mis à disposition par les plateformes que 3 à 4 mois après la vente. Les données affichées doivent donc remonter à au moins 3 ou 4 mois. Si une date de diffusion numérique survient après le 20 d'un mois, les données pourraient être retardées d'un mois supplémentaire.`,
  },
  {
    id: `8`,
    question: `Est-ce que vous distribuez ma musique sur Instagram ?`,
    response: `Bien qu'il ne s'agisse pas d'une plateforme de streaming, Instagram fait partie des plateformes disponibles sur lesquelles nous distribuons votre musique. Veuillez simplement sélectionner toutes les plateformes dans la section Livraison de votre sortie et la livraison à Instagram sera automatique.`,
  },
  {
    id: `9`,
    question: `Est-il possible de savoir à quelle heure ma sortie sera disponible sur chaque plateforme, à la date de mise en ligne ?`,
    response: `Chaque plateforme fonctionne de manière autonome et chaque sortie sera mise en ligne pendant la période qui comprend les 24 heures entières, de minuit à 23 h 59, à la date de mise en ligne prévue.<br />
    Il n'est pas possible de déterminer à l'avance si votre sortie sera disponible à partir de minuit ou plus tard, car chaque plateforme à ses propres procédures de contrôle de qualité interne; dans tous les cas, votre sortie sera publié dans la journée prévue.`,
  },
];

export default faq;